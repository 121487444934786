import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";

export interface UiState {
    showLoader: boolean;
}
@Injectable({
    providedIn: 'root'
})
export class UiService extends ComponentStore<UiState> {
    constructor() {
        super({
            showLoader: false,
        })
    }

    showLoader$ = this.select(state => state.showLoader);

    showLoader(v: boolean): void {
        this.patchState({ showLoader: v })
    }
}