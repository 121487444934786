export enum PermissionedAction {
    ReadFileMetadatas = 'read_file_metadatas',
    CreateFileMetadata = 'create_file_metadata',

    ReadInboxDocumentLogs = 'read_inbox_document_logs',
    CreateInboxDocumentLog = 'create_inbox_document_log',
    UpdateInboxDocumentLog = 'update_inbox_document_log',

    ReadOutboxUserDocumentLogs = 'read_outbox_user_document_logs',
    ReadOutboxCompanyDocumentLogs = 'read_outbox_company_document_logs',

    ReadArchvies = 'read_archives',
    ReadOrganizations = 'read_organizations',
    ReadCompany = 'read_company',
    CreateCompany = 'create_company',
    UpdateCompany = 'update_company',
    DeleteCompany = 'delete_company',

    ReadUsers = 'read_users',
    ReadUser = 'read_user',
    CreateUser = 'create_user',
    UpdateUser = 'update_user',
    DeleteUser = 'delete_user',

    ReadWorkqueueDocumentLogs = 'read_wq_document_logs',
    ReadWorkqueueDocumentLog = 'read_wq_document_log',
    UpdateWorkqueueDocumentLog = 'update_wq_document_log',

    ReadParameters = 'read_parameters',
    CreateParameter = 'create_parameter',
    UpdateParameter = 'update_parameter',
    DeleteParameter = 'delete_parameter',

    ReadResearchers = 'read_researchers',
    ReadResearcher = 'read_researcher',
    CreateResearcher = 'create_researcher',
    UpdateResearcher = 'update_researcher',
    DeleteResearcher = 'delete_researcher',

    ReadResearch = 'read_research',
    CreateResearch = 'create_research',
    UpdateResearch = 'update_research',

    ReadFunds = 'read_funds',
    ReadFund = 'read_fund',
    CreateFund = 'create_fund',
    UpdateFund = 'update_fund',
    DeleteFund = 'delete_fund',

    ReadLists = 'read_lists',
    ReadList = 'read_list',
    CreateList = 'create_list',
    UpdateList = 'update_list',
    DeleteList = 'delete_list',

    ReadCases = 'read_cases',
    ReadCase = 'read_case',
    CreateCase = 'create_case',
    UpdateCase = 'update_case',
    DeleteCase = 'delete_case',

    ReadDocuments = 'read_documents',
    ReadDocument = 'read_document',
    CreateDocument = 'create_document',
    UpdateDocument = 'update_document',
    DeleteDocument = 'delete_document',

    ReadLogs = 'read_logs',
    ReadSearch = 'read_search',
}

export type PermissionedActionValues = `${PermissionedAction}`;