import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _storage = sessionStorage;
    private _tokenField = 'token';

    saveToken(token: string): void {
        this._storage.setItem(this._tokenField, token);
    }
    getToken(): string | null {
        return this._storage.getItem(this._tokenField);
    }
    removeToken(): void {
        this._storage.removeItem(this._tokenField);
    }
}