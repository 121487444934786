import { AppComponent } from './app/app.component';
import { TranslocoRootModule } from './app/transloco-root.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { APP_INITIALIZER, inject, importProvidersFrom, ErrorHandler } from '@angular/core';
import { tokenInterceptor } from './app/interceptors/token.interceptor';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';
import { MatDialogModule } from '@angular/material/dialog';
import { StorageService } from './app/services/storage.service';
import { AuthStore } from './app/store/auth.store';
import { MatNativeDateModule } from '@angular/material/core';
import { provideMatDateFormat } from './app/common/providers/provideMatDateFormat';
import { GlobalErrorHandlerService } from './app/services/global-error-handler.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './app/common/providers/provideMatPaginator';
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslocoRootModule,
      MatDialogModule,
      MatNativeDateModule
    ),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const storage = inject(StorageService);
        const store = inject(AuthStore);
        return () => {
          const token = storage.getToken();
          if (!!token) {
            store.setToken(token);
          }
          return Promise.resolve('TOKEN INITIALIZED');
        };
      },
      multi: true,
    },

    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

    provideAnimations(),

    provideHttpClient(withInterceptors([tokenInterceptor])),

    provideRouter(APP_ROUTES),

    provideMatDateFormat()
  ],
});
