import { HttpContext, HttpContextToken } from "@angular/common/http";

export enum HttpResource {
    PUBLIC = 'public',
    PRIVATE = 'private',
    LOCAL = 'local',
}


export namespace  HttpResourceContext {
    const HTTP_RESOURCE_CONTEXT_TOKEN = new HttpContextToken(() => HttpResource.PRIVATE);

    export function get(context: HttpContext) {
        return context.get(HTTP_RESOURCE_CONTEXT_TOKEN);
    }

    export function create(resource: HttpResource, context?: HttpContext) {
        const _context = context ?? new HttpContext();
        return _context.set(HTTP_RESOURCE_CONTEXT_TOKEN, resource);
    }

    export function isPublicResource(context: HttpContext) {
        return get(context) == HttpResource.PUBLIC;
    }

    export function isPrivateResource(context: HttpContext) {
        return get(context) == HttpResource.PRIVATE;
    }

    export function isLocalResource(context: HttpContext) {
        return get(context) == HttpResource.LOCAL;
    }
    
    export function createPublicResourceContext(context?: HttpContext) {
        return create(HttpResource.PUBLIC, context);
    }

    export function createPrivateResourceContext(context?: HttpContext) {
        return create(HttpResource.PRIVATE, context);
    }

    export function createLocalResourceContext(context?: HttpContext) {
        return create(HttpResource.LOCAL, context);
    }
}