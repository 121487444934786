<div *ngIf="fileMetadata" class="file-info">
    <p class="file-name">{{fileMetadata.originalName}}</p>
    <ng-container *ngIf="safeUrl">
        <section class="file-viewer-container">
            <iframe *ngIf="documentGroup == DocumentGroup.Paper" [src]="safeUrl"></iframe>
            <video *ngIf="documentGroup == DocumentGroup.Movie" controls>
                <source [src]="safeUrl" [type]="fileMetadata.contentType">
            </video>
            <audio *ngIf="documentGroup == DocumentGroup.Sound" controls>
                <source [src]="safeUrl" [type]="fileMetadata.contentType">
            </audio>
            <img *ngIf="documentGroup == DocumentGroup.Photo" [src]="safeUrl" />
            <p *ngIf="documentGroup == DocumentGroup.Unknown">Sənədi göstərmək mümkün olmadı...</p>
        </section>
    </ng-container>
</div>