import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

const X_DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const DATE_FORMATS_ONLY_YEAR = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY'
  }
};


export class XDateAdapter extends MomentDateAdapter {
  constructor() {
    super('az');
  }
}


export function provideMatDateFormat() {
  return [
    { provide: DateAdapter, useClass: XDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: X_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'az' }
  ]
}

export function provideMatYearFormat() {
  return [
    { provide: DateAdapter, useClass: XDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS_ONLY_YEAR },
    { provide: MAT_DATE_LOCALE, useValue: 'az' }
  ]
}