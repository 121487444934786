<mat-card>
    <mat-card-content>
      <section class="header">
        <h5>{{title}}</h5>
        <div class="header-actions">
          <ng-content select="[layout-header-actions]"></ng-content>
        </div>
      </section>
      <section class="content">
        <ng-content select="[layout-content]"></ng-content>
      </section>
      <section class="footer">
        <ng-content select="[layout-footer-actions]"></ng-content>
      </section>
    </mat-card-content>
</mat-card>