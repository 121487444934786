import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PageOptions } from 'src/app/types';
import { NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    NgIf,
    NgFor,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
  ],
})
export class PaginationComponent {
  @Input() page: number = 0;
  @Input() size: number = 10;
  @Input() total: number = 0;
  @Output() paginate: EventEmitter<PageOptions> = new EventEmitter();

  pageSizes: number[] = [10, 25, 50, 100];

  get start() {
    return this.page * this.size + 1;
  }

  get end() {
    const val = (this.page + 1) * this.size;
    return val < this.total ? val : this.total;
  }

  get pageCount() {
    return Math.ceil(this.total / this.size);
  }

  onSelectionChange(size: number) {
    this.size = size;
    this.paginate.emit({ pageIndex: this.page, pageSize: this.size });
  }

  onChangePage(amount: number) {
    this.page = this.page + amount;
    this.paginate.emit({ pageIndex: this.page, pageSize: this.size });
  }
}
