import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, map } from "rxjs"
import { PermissionedAction, User, UserCredentials } from "../types"
import { environment } from "src/environments/environment";
import { PermissionedActionValues } from "../types/permission";


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private http: HttpClient
    ) {}

    login(credentials: UserCredentials): Observable<string> {
        return this.http.post<{ access_token: string }>(`${environment.apiBaseUrl}/auth/login`, credentials).pipe(map(response => response.access_token));
    }
    fetchUserInfo(): Observable<User> {
        return this.http.get<User>(`${environment.apiBaseUrl}/auth/user`);
    }
    fetchUserPermissions(): Observable<Partial<Record<PermissionedActionValues, boolean>>> {
        return this.http.get<Partial<Record<PermissionedAction, boolean>>>(`${environment.apiBaseUrl}/auth/permissions`)
    }
}