import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from './common/components';
import { UiService } from './services/ui.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, LoaderComponent, AsyncPipe, NgIf]
})
export class AppComponent {
  title = 'meais';

  uiService = inject(UiService);

  showLoader$ = this.uiService.showLoader$.pipe(debounceTime(50));
}
