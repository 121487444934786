import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from "../common/components/error-dialog/error-dialog.component";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) { }

  handleError(error: any): void {
    const dialog = this.injector.get(MatDialog);
    const message = error.error?.message ?? error.message ?? 'Unknown error';
    dialog.open(ErrorDialogComponent, {
        data: { message },
        width: '520px',
    })
  }
}