import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, first, switchMap } from "rxjs";
import { HttpResourceContext } from "../constants/http-resource-context";
import { AuthStore } from "../store/auth.store";

export const tokenInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const context = req.context;
    if (!HttpResourceContext.isPrivateResource(context)) return next(req);

    const store = inject(AuthStore);

    return store.token$.pipe(
        first(),
        switchMap(token => {
            if (!!token) {
                req = req.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            }

            return next(req);
        })
    )
}