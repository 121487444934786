import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, createUrlTreeFromSnapshot } from "@angular/router";
import { AuthStore } from "../store/auth.store";
import { filter, map, tap } from "rxjs";
import { PermissionedActionValues } from "../types/permission";

export const hasPermissionGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store = inject(AuthStore);
    const allowedPermission = next.data['permission'] as PermissionedActionValues;
    if(!allowedPermission) return true;

    return store.permissions$.pipe(
        tap((p) => {
            if(!p) store.fetchUser();
        }),
        filter(p => !!p),
        map(userPermissions => userPermissions?.[allowedPermission] ? true : createUrlTreeFromSnapshot(next, ['/', 'home'])),
    )
}