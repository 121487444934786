import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgTemplateOutlet, NgFor, NgStyle } from '@angular/common';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, NgFor],
})
export class TableComponent {
  @Input() pageTitle: string = '';
  @Input() data: Array<any> = [];
  @Input() headerTemplate!: TemplateRef<any>;
  @Input() bodyTemplate!: TemplateRef<any>;
  @Input() footerTemplate!: TemplateRef<any>;
  @Output() rowClick = new EventEmitter<any>();

  rowClicked: number = -1;

  onRowClick(item: any, index: number) {
    this.rowClicked = index;
    this.rowClick.emit(item);
  }
}
