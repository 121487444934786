import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf],
  template: `
    <ng-container>
      <mat-spinner [diameter]="32" *ngIf="!fullscreen"></mat-spinner>

      <div *ngIf="fullscreen" class="overlay">
        <div class="overlay-content">
          <mat-spinner [diameter]="64"></mat-spinner>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .overlay {
        height: 100%;
        width: 100%;
        position: fixed; /* Stay in place */
        z-index: 7001; /* Sit on top */
        left: 0;
        top: 0;
        background-color: rgb(0, 0, 0); /* Black fallback color */
        background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity */
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.5s;
      }

      .overlay-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
  ],
})
export class LoaderComponent {
  @Input() fullscreen: boolean = false;
}
