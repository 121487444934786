import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DocumentGroup, FileMetadata } from "src/app/types";

@Component({
    selector: 'app-file-metadata-viewer',
    templateUrl: './file-metadata-viewer.component.html',
    styleUrls: ['./file-metadata-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf
    ]
})
export class FileMetadataViewerComponent {
    DocumentGroup = DocumentGroup;
    private _sanitizer = inject(DomSanitizer);

    @Input() fileMetadata: FileMetadata | null = null;
    
    @Input() 
    get signedUrl() { return this._signedUrl }
    set signedUrl(url: string | null) {
        this._signedUrl = url;
        this.safeUrl = !!url ? this._sanitizer.bypassSecurityTrustResourceUrl(url) : null
    }
    private _signedUrl: string | null = null

    safeUrl: SafeResourceUrl | null = null;

    get documentGroup() {
        return this.fileMetadata?.documentGroup;
    }
}