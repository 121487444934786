import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-form-dialog-layout',
  templateUrl: './form-dialog-layout.component.html',
  styleUrls: ['./form-dialog-layout.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslocoModule],
})
export class FormDialogLayoutComponent {
  @Input() title: string = '';
  @Input() formElementId: string = '';
}
