<div class="table-container">
    <table>
        <thead>
            <tr>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            </tr>
        </thead>
        <tbody>
            <!-- [style.opacity]="(pageTitle === 'workqueue' && item.status !== 1) ? '0.6' : '1'" -->
            <!-- <tr *ngFor="let item of data; let i = index"
                [style.font-weight]="(pageTitle === 'workqueue' && item.status !== 1) ? '400' : '700'">
                <ng-container *ngTemplateOutlet="bodyTemplate; context: { item: item, index: i }"></ng-container>
            </tr> -->
            <tr *ngFor="let item of data; let i = index" [class.opaque]="pageTitle === 'workqueue' && item.status !== 1"
                (click)="pageTitle === 'control' && onRowClick(item, i)" [class.choosen]="pageTitle === 'control'"
                [class.row-clicked]="rowClicked === i">
                <ng-container *ngTemplateOutlet="bodyTemplate; context: { item: item, index: i }"></ng-container>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </tr>
        </tfoot>
    </table>
</div>