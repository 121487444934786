import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {

  override itemsPerPageLabel: string = 'Sətir sayı:';
  override nextPageLabel: string = 'Növbəti səhifə';
  override previousPageLabel: string = 'Əvvəlki səhifə';
  override firstPageLabel: string = 'İlk səhifə';
  override lastPageLabel: string = 'Son səhifə';


  constructor() {
    super();
  }
}
