import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss'],
  standalone: true,
  imports: [MatCardModule],
})
export class CardLayoutComponent {
  @Input() title: string = '';
}
