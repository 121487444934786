import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, RouterStateSnapshot } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthStore } from "../store/auth.store";

export const notAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store = inject(AuthStore);

    return store.token$.pipe(map(token => {
        return !token ? true : createUrlTreeFromSnapshot(next, ['/', 'home'])
    }))
}