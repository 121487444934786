import { Entity, IdFieldType } from "./common";
import { Company } from "./company";

export interface Fund extends Entity {
    fundNumber: string;
    name: string;
    shortName: string;
    fundDateInterval: string;
    locatedPlace: string;
    locatedBookshelf: string;
    locatedShelf: string;
    dateAndRequisites: string;

    companyId: IdFieldType;
    company?: Company;

    permissions?: {
        update: boolean;
        delete: boolean;
        readLists: boolean;
        createList: boolean;
    }
}

export interface CreateFundDTO {
    fundNumber: string;
    name: string;
    shortName: string;
    fundDateInterval: string;
    locatedPlace: string;
    locatedBookshelf: string;
    locatedShelf: string;
    dateAndRequisites: string;
    companyId: IdFieldType;
}

export interface UpdateFundDTO {
    fundNumber: string;
    name: string;
    shortName: string;
    fundDateInterval: string;
    locatedPlace: string;
    locatedBookshelf: string;
    locatedShelf: string;
    dateAndRequisites: string
}

export interface SearchFundDTO {
    fundNumber?: string;
    name?: string;
    shortName?: string;
    fundDateInterval?: string;
    locatedPlace?: string;
    locatedBookshelf?: string;
    locatedShelf?: string;
    dateAndRequisites?: string

    companyId?: IdFieldType;
    companyName?: string;
    includeCompanyRelation?: boolean;

};