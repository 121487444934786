export enum ArchiveGroup {
    StateArchive = 1,
    StateArchiveBranch = 2,
    RegionalStateArchive = 3,
    InternalArchive = 4
}

export enum CompanyGroup {
    Archive = 1,
    Organization = 2
}

export enum State {
    Accepted = 1,
    Rejected = 2,
    Pending = 3,
    Registered = 4,
    SentToArchive = 5
}

export enum Availability {
    Available = 1,
    Busy = 2,
}

export enum DocumentGroup {
    Paper = 1,
    Movie = 2,
    Photo = 3,
    Sound = 4,
    Unknown = 100,
}

export enum Status {
    New = 1,
    Active = 2,
    Passive = 3,
}

export enum UserRole {
    AppAdmin = 10,
    // SuperAdmin = 20,
    Admin = 30,
    SuperUser = 40,
    User = 50,
    Registrar = 60,
    Controller = 70,
    Researcher = 80
}

export enum DocumentLogSource {
    FileMetadata = 1,
    Document = 2
}

export type SortOption = 'asc' | 'desc';

export enum ParameterType {
    Chapter = 1,
    Classification = 2,
    Genre = 3,
    Subject = 4,
    Title = 5,
    Type = 6,
    Inquiry = 7,
}

export enum CaseDateType {
    Unknown = 1,
    ExactDate = 2,
    Year = 3,
    // MonthYear = 4,
    // DayMonth = 5
}

export enum Footage {
    Short = 1,
    Full = 2
}


export enum ObjectType {
    Company = 1,
    User = 2,
    Fund = 3,
    List = 4,
    Case = 5,
    Document = 6,
    DocumentLog = 7,
    FileMetadata = 8,
    Parameter = 9,
    ResearcherProfile = 10,
    Research = 11
}


export enum ActionType {
    Read = 1,
    Create = 2,
    Update = 3,
    Delete = 4,
    View = 5,
    Login = 6,
    Logout = 7,
    Upload = 8,
    Accept = 9,
    Reject = 10
}



export enum PermissionedAction {
    ReadFileMetadatas = 'read_file_metadatas',
    CreateFileMetadata = 'create_file_metadata',

    ReadInboxDocumentLogs = 'read_inbox_document_logs',
    CreateInboxDocumentLog = 'create_inbox_document_log',
    UpdateInboxDocumentLog = 'update_inbox_document_log',

    ReadOutboxUserDocumentLogs = 'read_outbox_user_document_logs',
    ReadOutboxCompanyDocumentLogs = 'read_outbox_company_document_logs',

    ReadArchvies = 'read_archives',
    ReadOrganizations = 'read_organizations',
    ReadCompany = 'read_company',
    CreateCompany = 'create_company',
    UpdateCompany = 'update_company',
    DeleteCompany = 'delete_company',

    ReadUsers = 'read_users',
    CreateUser = 'create_user',
    UpdateUser = 'update_user',
    DeleteUser = 'delete_user',

    ReadWorkqueueDocumentLogs = 'read_wq_document_logs',
    ReadWorkqueueDocumentLog = 'read_wq_document_log',
    UpdateWorkqueueDocumentLog = 'update_wq_document_log',

    ReadParameters = 'read_parameters',
    CreateParameter = 'create_parameter',
    UpdateParameter = 'update_parameter',
    DeleteParameter = 'delete_parameter',

    ReadResearchers = 'read_researcher',
    CreateResearcher = 'create_researcher',
    UpdateResearcher = 'update_researcher',
    DeleteResearcher = 'delete_researcher',

    ReadResearch = 'read_research',
    CreateResearch = 'create_research',
    UpdateResearch = 'update_research',

    ReadFunds = 'read_funds',
    ReadFund = 'read_fund',
    CreateFund = 'create_fund',
    UpdateFund = 'update_fund',
    DeleteFund = 'delete_fund',

    ReadLists = 'read_lists',
    ReadList = 'read_list',
    CreateList = 'create_list',
    UpdateList = 'update_list',
    DeleteList = 'delete_list',

    ReadCases = 'read_cases',
    ReadCase = 'read_case',
    CreateCase = 'create_case',
    UpdateCase = 'update_case',
    DeleteCase = 'delete_case',

    ReadDocuments = 'read_documents',
    ReadDocument = 'read_document',
    CreateDocument = 'create_document',
    UpdateDocument = 'update_document',
    DeleteDocument = 'delete_document',

    ReadLogs = 'read_logs',
    ReadSearch = 'read_search',
}