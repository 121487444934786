<div class="pagination-container">
    <section style="display: flex; align-items: center; gap: 20px;">
        <div *ngIf="total">
            <span>{{start}}</span>
            <span>-</span>
            <span>{{end}}</span>
        </div>
        <!-- <div>
            <mat-form-field style="width: 100px; display: flex; flex-direction: row;">
                <mat-select [(ngModel)]="size" (selectionChange)="onSelectionChange($event.value)">
                    <mat-option *ngFor="let pageSize of pageSizes" [value]="pageSize">{{pageSize}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
    </section>

    <section style="display: flex; align-items: center; justify-content: center;">
        <button type="button" mat-icon-button (click)="onChangePage(-1)" [disabled]="page <= 0">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <span style="padding: 0px 8px;">{{page + 1}}</span>
        <button type="button" mat-icon-button (click)="onChangePage(1)" [disabled]="page >= pageCount - 1">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </section>

    <section>
        <span>{{'total' | transloco}}</span>
        <span style="padding-left: 8px">{{total}}</span>
    </section>
</div>