import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
    selector: 'app-error-dialog',
    standalone: true,
    imports: [TranslocoModule, MatDialogModule, MatButtonModule],
    template: `
      <h2 mat-dialog-title>{{'errorOccured' | transloco}}</h2>
        <mat-dialog-content>
          <p style="color: #D50000">{{message}}</p>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button mat-button color="warn" (click)="onCancel()" type="button">
            {{'close' | transloco}}
          </button>
        </mat-dialog-actions>
    `,
    styles: [
     `
        mat-dialog-actions {
          display: flex;
          justify-content: flex-end;
        }
      `,
    ],
  })
  export class ErrorDialogComponent implements OnInit {
    message: string = '';
  
    constructor(
      private dialogRef: MatDialogRef<ErrorDialogComponent>,
      @Inject(MAT_DIALOG_DATA) private data: {
        message: string
    }) {
  
    }
  
    ngOnInit(): void {
      this.message = this.data.message
    }
  
    onCancel() {
      this.dialogRef.close(null);
    }
  }